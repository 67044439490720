import mainApi from "./mainApi";

export function userApi() {
    return {
        // get the user that is logged in
        getUser: function() {
            return mainApi.get(`/user`);
        },
        // get the coaching team of a client
        getCoaches: function() {
            return mainApi.get('/user/coaching-team');
        },
        // get a list of clients
        getClients: function(page, query) {
            return mainApi.post(`/user/find/clients/${page}?${query}`);
        },
        // get a users health goal
        getHealthGoal: function(userId) {
            return mainApi.get(`/user/healthgoal/?user_id=${userId}`);
        },
        // get a single coach
        getCoach(username) {
            return mainApi.get(` /user/coach/single/${username}`);
        },
        // update a member's profile
        updateProfile: function (username, query) {
            return mainApi.post(`/user/${username}/client-detail/add?${query}`)
        },
        updateStatus: function(username, status) {
            return mainApi.put(`/user/change-status/${status}/username/${username}`);
        },
        setDemoUser: function (userId, val) {
            return mainApi.put(`/user/client/change/demo/${userId}/${val}`);
        },
        // get a full list of members either assigned to you or all for admins
        getFullMemberList: function() {
            return mainApi.get(`/user/filter/members/`);
        },
        // switch a members coach nudge indicator
        coachNudge: function(bool) {
            return mainApi.post(`/user/coach/nudge/${bool}`);
        },
        updateTour: function(id) {
            return mainApi.post(`/user/tour/log/${id}`)
        },
        // get the organization email preferences of a member
        getEmailList: function(userId) {
            let url = '/user/email/preferences';
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // update the organization email preferences of a member
        updateEmailList: function (listId, optIn) {
            return mainApi.put(`/user/email/preference/${listId}/${optIn}`)
        },
        // get a list of all the coaches
        getCoachList: function(){
            return mainApi.get(`/user/coach-list`)
        },
        // get the users preferred alert options
        getAlertOptions: function(userId) {
            let url = '/user/game/options';
            if (userId) url += `?user_id=${userId}`
            return mainApi.get(url);
        },
        // set a members daily goal
        updateDailyGoal: function(goal, userId) {
            let url = `/user/game/options/daily-goal/${goal}`;
            if (userId) url += `?user_id=${userId}`
            return mainApi.put(url)
        },
        // set a members alert notification option
        updateAlertNotification: function(alert, userId) {
            let url = `/user/game/options/points-alert/${alert}`;
            if (userId) url += `?user_id=${userId}`;
            return mainApi.put(url)
        },
        // set a users nickname for the leaderboard
        updateNickname: function(nickname, userId) {
            let url = `/user/update/nickname/${nickname}`;
            if (userId) url += `?user_id=${userId}`;
            return mainApi.put(url)
        },
        // get a user's activity feed for admins
        getActivityFeed: function(userId, start, end, page) {
            return mainApi.get(`/score/member/activity/feed?user_id=${userId}&start_date=${start}&end_date=${end}&page=${page}`);
        },
        // get a users level activity
        getLevelActivity: function (userId, start, end) {
            return mainApi.get(`/score/member/level/graph?user_id=${userId}&start_date=${start}&end_date=${end}`);
        },
        // get a users score activity
        getScoreBreakoutMetrics: function (userId, start, end) {
            return mainApi.get(`/score/member/activity/graph?user_id=${userId}&start_date=${start}&end_date=${end}`);
        },
        // get a users average score metrics per period
        getScoreAverages: function (userId) {
            return mainApi.get(`/score/member/averages/${userId}`);
        },
        // get a users current streak data for admins
        getAdminStreak: function (username) {
            return mainApi.get(`/score/streak?username=${username}`);
        },
        //post health connect fact status for user
        updateHealthConnectFacts: function (healthConnectStatus) {
            return mainApi.post(`/user/health-connect/status/${healthConnectStatus}`);
        },
        // get a users current streak data for admins
        changePassword: function (password, newPassword, confirmPassword) {
            return mainApi.put(`/user/update-password`, {password, newPassword, confirmPassword});
        },
    }
}