import mainApi from "./mainApi";

export function authApi() {
    return {
        // check if a user has appropriate auth credentials saved in browser
        checkLogin: async function() {
            return mainApi.put('/login/check');
        },
        login: async function(params, challengeToken) {
            const {email, password, code, backup, save} = params;
            const data = {username: email, password};
            if (code) data.code = code;
            if (backup) data.backup_code = backup;
            if (save) data.save = save;
            if (challengeToken) data.challengeToken = challengeToken;
            return mainApi.post(`/login/`, data);
        },
        logout: function() {
            return mainApi.get('/logout');
        },
        recordSession: function(seconds, sessionId) {
            return mainApi.post(`/facts/record/session/${sessionId}/${seconds}  `);
        },
        verifyPassword: async function(password) {
            return mainApi.post(`/login/verify-password`, {password});
        },
        setupMFA: async function(method, password) {
            return mainApi.post(`/login/get-mfa-code`, {mfaMethod: method, password});
        },
        confirmMFA: async function(code, method) {
            return mainApi.post(`/login/verify-mfa-setup`, {mfaMethod: method, code});
        },
        disableMFA: async function(password) {
            // remove challenge token if no longer valid
            // await SecureStore.deleteItemAsync('challengeToken')
            //     .catch(e => {
            //         throw e
            //     });
            return mainApi.post(`/login/disable-mfa`, {password});
        },
    }
}